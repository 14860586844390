
import { Options, Vue } from 'vue-class-component';
import Charity from '../models/charity';
import axios, { AxiosError } from "axios";

@Options({
    data() {
        return {
            charities: Array<Charity>(),
            filteredCharities: Array<Charity>(),
            products: [],
            locations: [],
            modalities: [],
            recipients: [],
            nameFilter: "",
            locationFilter: "All",
            productFilter: [],
            modalityFilter: [],
            recipientFilter: [],
            isDebug: false,
        };
    },
    methods: {
        filterCharities() {
            let filtered = new Array<Charity>();
            if(!this.charities) {
                return filtered;
            }

            for(let i = 0; i < this.charities.length; i++) {
                let include = true;
                const charity = this.charities[i];

                // Filter by name
                if(this.nameFilter.trim()) {
                    if(charity.name.toLowerCase().indexOf(this.nameFilter.trim().toLowerCase()) == -1) {
                        include = false;
                    }
                }

                // Filter by modality
                if(this.modalityFilter.length > 0 && include) {
                    include = this.isArrayMatch(charity.modalities, this.modalityFilter);
                }

                // Filter by product
                if(this.productFilter.length > 0 && include) {
                    include = this.isArrayMatch(charity.products, this.productFilter);
                }

                // Filter by recipient
                if(this.recipientFilter.length > 0 && include) {
                    include = this.isArrayMatch(charity.recipients, this.recipientFilter);
                }

                // Filter by location
                if(this.locationFilter !== "All" && include) {
                    include = this.isLocationMatch(charity.locations);
                }

                if(include) {
                    filtered.push(charity);
                }

            }
            this.filteredCharities = filtered;
        },
        modalityChanged(event: any) {
            const modality = event.target.value;
            this.toggleArrayItem(this.modalityFilter, modality);
        },
        productChanged(event: any) {
            const product = event.target.value;
            this.toggleArrayItem(this.productFilter, product);
        },
        recipientChanged(event: any) {
            const recipient = event.target.value;
            this.toggleArrayItem(this.recipientFilter, recipient);
        },
        locationChanged(event: any) {
            this.filterCharities();
        },
        toggleArrayItem(array: [string], item: string) {
            const index = array.indexOf(item);
            if(index > -1) {
                array.splice(index, 1);
            }
            else {
                array.push(item);
            }
            this.filterCharities();
        },
        isArrayMatch(array1: [string], array2:[string]) {
            let isMatch = false;
            array1.forEach((item1) => {
                array2.forEach(item2 => {
                    if(item1 === item2) {
                        isMatch = true;
                    }
                });
            });
            return isMatch;
        },
        isLocationMatch(locations: [string]) {
            if(this.locationFilter === "All") {
                return true;
            }
            for(let i = 0; i < locations.length; i++) {
                const location = locations[i];
                if(location === "All") {
                    return true;
                }
                if(location === "All (Except Alaska)" && this.locationFilter !== "Alaska") {
                    return true;
                }
                if(location === "All (Except South Dakota)" && this.locationFilter !== "South Dakota") {
                    return true;
                }
                if(location === this.locationFilter) {
                    return true;
                }
            }
            return false;
        }
    },
    mounted() {
      axios
        .get<[Charity]>("charities.json")
        .then((response) => {
            this.charities = response.data.sort((a, b) => {
                if(a.name && b.name) {
                    return a.name?.localeCompare(b.name);
                }
                return 0;
            });
            let products = new Array<string>();
            let locations = new Array<string>();
            let modalities = new Array<string>();
            let recipients = new Array<string>();
            this.charities.forEach((charity: Charity) => {
                charity.products?.forEach((product) => {
                    if(products.indexOf(product) === -1) {
                        products.push(product);
                    }
                })
                charity.locations?.forEach((location) => {
                    if(locations.indexOf(location) === -1) {
                        locations.push(location);
                    }
                })
                charity.modalities?.forEach((modality) => {
                    if(modalities.indexOf(modality) === -1) {
                        modalities.push(modality);
                    }
                })
                charity.recipients?.forEach((recipient) => {
                    if(recipients.indexOf(recipient) === -1) {
                        recipients.push(recipient);
                    }
                })
            });
            products.sort((a, b) => {
                return a.localeCompare(b);
            });
            this.products = products;
            locations.sort((a, b) => {
                if(a === "All") {
                    return -1;
                }
                if(b === "All") {
                    return 1;
                }
                if(a.indexOf("All") === 0 && b.indexOf("All") !== 0) {
                    return -1;
                }
                if(a.indexOf("All") === 0 && b.indexOf("All") === 0) {
                    return a.localeCompare(b);
                }
                return a.localeCompare(b);
            });
            this.locations = locations;
            modalities.sort((a, b) => {
                return a.localeCompare(b);
            })
            this.modalities = modalities;
            recipients.sort((a, b) => {
                return a.localeCompare(b);
            })
            this.recipients = recipients;
            this.filterCharities();
            // console.log(response.data);
        })
        .catch((error: AxiosError) => {
            console.log(error);
            // console.log("API Error: " + JSON.stringify(error.response, null, 4));
        });
    },
})
export default class Home extends Vue {}
